import './scss/themes/accru/style.scss';
import { useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { effect } from '@preact/signals-react';

// GLOBAL //
import Redirect from 'components/global/Redirect';
import Toastr from 'components/global/Toastr';
import PublicRoute from 'components/global/Routes/PublicRoute';
import PrivateRoute from 'components/global/Routes/PrivateRoute';
import Alert from 'components/global/Alert';

// SIGNALS //
import ScrollToTop from 'components/global/ScrollToTop/ScrollToTop';
import { $global } from 'signals/Global.signals';

// VIEWS //
import AcceptInvite from 'components/views/Auth/AcceptInvite';
import ForgotPassword from 'components/views/Auth/ForgotPassword';
import Login from 'components/views/Auth/Login';
import ResetPassword from 'components/views/Auth/ResetPassword';
import SignUp from 'components/views/Auth/SignUp/SignUp';
import SignUpEmbed from 'components/views/Auth/SignUp/SignUpEmbed';

import BillAdd from 'components/views/BillAdd';
import BillDetail from 'components/views/BillDetail';
import Bills from 'components/views/Bills';
import CustomerAdd from 'components/views/CustomerAdd';
import Customers from 'components/views/Customers';
import InvoiceAdd from 'components/views/InvoiceAdd';
import IntuitRedirect from 'components/global/IntuitRedirect';
import InvoiceDetail from 'components/views/InvoiceDetail';
import Invoices from 'components/views/Invoices';
import Onboarding from 'components/views/Onboarding';
import Payment from 'components/views/Payment';
import Settings from 'components/views/Settings';
import Statement from 'components/views/Statement/Statement';
import VendorAdd from 'components/views/VendorAdd';
import Vendors from 'components/views/Vendors';
import NotFound from 'components/views/NotFound';
import Sync from 'components/views/Sync';
import StyleGuide from 'components/views/StyleGuide';

// FUNCTIONS //
import { handleFirebaseLogin, handleFirebaseLogout, handleFirebaseTokenRefresh } from 'components/views/Auth/_shared/Auth.helpers';
import { auth } from 'utils/firebase';
import history from 'utils/history';
import { getFirebaseToken, startSessionTimer } from 'utils/auth';
// import { ErrorBoundary } from 'react-error-boundary';
import ContentWrapper from 'components/global/ContentWrapper';
import './websockets';
import SettingsProfile from 'components/views/SettingsProfile/SettingsProfile';
import AreYouStillHereModal from 'components/global/AreYouStillHereModal';
import Disconnect from 'components/views/Disconnect';

let refreshFbTokenIntervalID = null;

effect(() => {
  const { isSignedIn } = $global.value;
  if (refreshFbTokenIntervalID) {
    clearInterval(refreshFbTokenIntervalID);
  }

  if (isSignedIn) {
    refreshFbTokenIntervalID = setInterval(async () => {
      const newIdToken = await getFirebaseToken();
      if (newIdToken) {
        return handleFirebaseTokenRefresh(newIdToken);
      }
      return null;
    }, 1000 * 60 * 5);
  }
});

const App = () => {
  useEffect(() => {
    const brand = {
      accru: 'https://accru.co',
      builder_pay_pro: '', // TODO: add BPP url here
    }[window.location.hostname] || 'accru';

    $global.update({ brand });

    if (brand === 'builder_pay_pro') {
      import('./scss/themes/bpp/style.scss').then((styles) => {
        document.body.className = styles.container;
      });
    } else {
      import('./scss/themes/accru/style.scss').then((styles) => {
        document.body.className = styles.container;
      });
    }

    const unsubscribe = auth.onAuthStateChanged(async (fbUser) => {
      if (fbUser) {
        await handleFirebaseLogin(fbUser);
        startSessionTimer();
      } else if ($global.value.isSignedIn) handleFirebaseLogout();

      $global.update({
        isLoading: false,
      });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const handleOffline = () => {
      $global.update({
        isBackendConnDown: true,
      });
    };
    window.addEventListener('offline', handleOffline);

    if (!navigator.onLine) {
      handleOffline();
    }

    return () => {
      window.removeEventListener('offline', handleOffline);
    };
  }, [history]);

  return (
    <>
      {/* <ErrorBoundary fallback={<h1>Something went very, very wrong.</h1>}> */}
      <Alert />
      <Toastr />
      <Router history={history}>
        {/* <ErrorBoundary fallback={<Redirect to="/404" />}> */}
        <ScrollToTop />
        <AreYouStillHereModal />
        <Switch>
          <Route
            path="/404"
            render={() => (
              <ContentWrapper fluid className="min-vh-100 px-0">
                <NotFound />
              </ContentWrapper>
            )}
          />
          <Route
            path="/disconnect"
            render={() => (
              <ContentWrapper fluid className="min-vh-100 px-0">
                <Disconnect />
              </ContentWrapper>
            )}
          />
          <PublicRoute path="/" exact component={Redirect} />
          <PublicRoute path="/login" component={Login} />
          <PublicRoute path="/password-reset" component={ResetPassword} />
          <PublicRoute path="/forgot-password" component={ForgotPassword} />
          <PublicRoute path="/signup/embed" exact component={SignUpEmbed} />
          <PublicRoute path="/signup" exact component={SignUp} />
          <PublicRoute path="/organization-invitation" allowSigned component={AcceptInvite} />
          <PublicRoute path="/statement/:id" exact allowSigned component={Statement} useNavbarPublic />
          <PrivateRoute path="/accounting/quickbooks" component={IntuitRedirect} />
          <PrivateRoute path="/onboarding" component={Onboarding} noNavbar />
          <PrivateRoute path="/payment" component={Payment} noNavbar />
          <PrivateRoute path="/customers/add" noNavbar component={CustomerAdd} />
          <PrivateRoute path="/customers/statement/:id" exact component={Statement} />
          <PrivateRoute path="/customers" component={Customers} />
          <PrivateRoute path="/vendors/add" noNavbar component={VendorAdd} />
          <PrivateRoute path="/vendors/statement/:id" exact component={Statement} />
          <PrivateRoute path="/vendors" component={Vendors} />
          <PrivateRoute path="/invoices/add" noNavbar component={InvoiceAdd} />
          <PrivateRoute path="/invoices/:id" component={InvoiceDetail} />
          <PrivateRoute path="/invoices" component={Invoices} />
          <PrivateRoute path="/bills/add" noNavbar component={BillAdd} />
          <PrivateRoute path="/bills/:id" component={BillDetail} />
          <PrivateRoute path="/bills" component={Bills} />
          <PrivateRoute path="/profile" component={SettingsProfile} />
          <PrivateRoute path="/settings" component={Settings} />
          <PrivateRoute path="/sync" noNavbar component={Sync} />
          <PrivateRoute path="/styles" component={StyleGuide} />
        </Switch>
        {/* </ErrorBoundary> */}
      </Router>
      {/* </ErrorBoundary> */}
    </>
  );
};

export default App;
