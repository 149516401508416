/* eslint-disable */
import { AccruClient } from '@accru/client';
import { handleNotification } from 'components/global/Alert/Alert';
import { fetchAndSetUserData, handleLogout } from 'components/views/Auth/_shared/Auth.helpers';
import { getConfig } from 'config/config';
import { $authToken } from 'signals/Authentication.signals';
import { $global } from 'signals/Global.signals';
import { auth } from 'utils/firebase';
import isBackendConnectionUp from 'utils/isBackendConnectionUp';

const BACKEND_GQL_ENDPOINT = getConfig('BACKEND_GRAPHQL_ENDPOINT');

export const reconnectServer = async () => {
  try {
    const isConnectionUp = await isBackendConnectionUp();
    if (isConnectionUp) {
      if ($global.value.isSignedIn) {
        const userData = await fetchAndSetUserData();
        if (!userData?.email) return false;
      }

      $global.update({
        isBackendConnDown: false,
      });

      return true;
    }
    throw new Error('Unable to access server');
  } catch (error) {
    $global.update({
      isBackendConnDown: true,
    });
    return false;
  }
};

export const handleBackendConnError = async () => {
  $global.update({
    isBackendConnDown: true,
  });
};

export const apiFormDataFetch = async ({
  path,
  formData = undefined,
  method = 'GET',
  token,
}) => {
  const methodToUse = method || (formData ? 'POST' : 'GET');

  const res = await fetch(BACKEND_GQL_ENDPOINT.replace('/graphql', '') + path, {
    method: methodToUse,
    body: formData,
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  if (!res.ok) {
    let errorMessage;
    try {
      const errorJson = await res.json();
      errorMessage = errorJson.message || JSON.stringify(errorJson);
    } catch (_) {
      errorMessage = await res.text();
    }
    throw new Error(errorMessage);
  }

  const result = await res.json();
  return result;
};

const GQL_ERROR_MAP = { // all other gql keys/codes default to a toast with variant-warning, unless overrides are passed in
  FORBIDDEN: {
    variant: 'danger',
  },
  ORGANIZATION_NOT_FOUND: {
    variant: 'danger',
  },
  INSUFFICIENT_PERMISSIONS: {
    variant: 'danger',
  },
  ACCOUNTING_PROVIDER_ERROR: {
    variant: 'danger',
  },
};

export const accruClient = new AccruClient({
  url: getConfig('ENV') === 'development' && !!BACKEND_GQL_ENDPOINT ? BACKEND_GQL_ENDPOINT : undefined,
  environment: getConfig('ENV') === 'production' ? 'production' : 'qa',

  getAuthToken: async () => {
    if (!$authToken.value) return null;

    const token = await auth.currentUser?.getIdToken();
    return token || null;
  },

  onAuthError: async () => {
    handleNotification('Please login again');
    handleLogout();
  },

  onGraphQLError: async (errors) => {
    errors.forEach(err => {
      const formattedError = err;
      let displayMessage = err.message;

      let code = err.extensions?.code;
      if (code && code.includes('/')) {
        code = code.split('/')[1];
      }

      formattedError.displayType = GQL_ERROR_MAP[code]?.displayType || 'toast';
      formattedError.variant = GQL_ERROR_MAP[code]?.variant || 'warning';

      const validationErrors = err.extensions?.exception?.validationErrors;

      if (validationErrors) {
        displayMessage = [];
        validationErrors?.forEach(v => {
          Object.values(v.constraints).forEach(c => {
            let formatted = c.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase());
            displayMessage.push(formatted)
          })
        })
      }

      formattedError.displayMessage = displayMessage;
    })
    return errors;
  },

  onNetworkError: async (error) => {
    error.displayMessage = error.message;
    handleBackendConnError(error)
    return error;
  },
});
