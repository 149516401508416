import { effect } from '@preact/signals-react';
import { io } from 'socket.io-client';
import { getConfig } from 'config/config';
import { $syncing, $toastr } from 'signals/Global.signals';
import $user from 'signals/User.signals';
import { $authToken } from 'signals/Authentication.signals';
import Signal from 'signals/Signal';
import { handleNotification } from 'components/global/Alert/Alert';
import { ACCT_PROVIDER } from '@accru/client';
import logEvent from 'utils/logEvent';

const completedSyncStatuses = ['SUCCESS', 'ERROR'];

const acctProviderNameMap = {
  [ACCT_PROVIDER.QUICKBOOKS]: 'QuickBooks',
};

const onOrganizationSync = (data) => {
  let syncData = [...$syncing.value.syncData || [], data];

  let progress = 0;
  let message = 'Loading...';

  if (data.type === 'SYNCHRONIZATION') {
    if (completedSyncStatuses.includes(data.status)) {
      if (data.status === 'SUCCESS') handleNotification(`Synchronized successfully with ${acctProviderNameMap[data.acct_provider]}!`, { variant: 'success' });
      else handleNotification(`Your synchronization with ${acctProviderNameMap[data.acct_provider]} was finished with issues. Please check your logs.`, { variant: 'warning' });

      const hasErrors = data.status === 'ERROR';

      message = hasErrors ? 'Sync finished with issues' : 'Sync completed';
      progress = 100;

      syncData = syncData.filter(sync => sync.organization_acct_provider_synchronization_id !== data.organization_acct_provider_synchronization_id);
      $syncing.update({
        isSyncing: false,
        isSyncRequested: false,
        isFinished: true,
        message,
        progress,
        hasErrors,
        syncData,
      });

      logEvent({
        name: 'sync',
        metadata: {
          message,
          progress,
          hasErrors,
        },
      });

      return;
    }

    if (data.type === 'BEGIN') {
      message = `Starting sync with ${acctProviderNameMap[data.acct_provider]}...`;
      progress = 0;
      $syncing.update({ isSyncRequested: true, isSyncing: true, isFinished: false, isSyncToastOpen: true, hasErrors: false, syncData, message, progress });
      return;
    }
  }

  if (data.type === 'POST_SYNC') {
    if (data.status === 'RUNNING') {
      progress = (data.count.local_update_success_count / data.count.remote_read_success_count) * 100;
      message = `Updating balances and statements ${data.count.local_update_success_count}/${data.count.remote_read_success_count}`;
    } else {
      message = 'Finishing up...';
      progress = 0;
    }
  } else {
    const entity = `${data.type.toLowerCase().replace('_', ' ')}s`;

    if (data.status === 'RUNNING') {
      const localItemCount = Object.keys(data.count).reduce((acc, cur) => (cur?.includes('local') ? data.count[cur] + acc : acc), 0);
      const remoteItemCount = Object.keys(data.count).reduce((acc, cur) => (cur?.includes('remote') ? data.count[cur] + acc : acc), 0);

      progress = (localItemCount / remoteItemCount) * 100;
      message = `Syncing ${localItemCount}/${remoteItemCount} ${entity}`;
    } else {
      message = `Starting sync for ${entity}`;
      progress = 0;
    }
  }

  $syncing.update({ isSyncRequested: true, isSyncing: true, isFinished: false, hasErrors: false, syncData, message, progress });

  let allToasts = Array.from($toastr.value.toasts);
  if (!allToasts.find(t => t.type === 'sync' && t.id === data.organization_acct_provider_synchronization_id)) {
    allToasts = allToasts.filter(t => t.type !== 'sync');
    allToasts.push({
      id: data.organization_acct_provider_synchronization_id,
      expanded: true,
      type: 'sync',
    });
    $toastr.update({
      toasts: allToasts,
    });
  }

  const providersInSync = syncData.reduce((acc, sync) => {
    if (completedSyncStatuses.includes(sync.status)) return acc;
    if (!acc.includes(sync.acct_provider)) acc.push(sync.acct_provider);
    return acc;
  }, []);

  $syncing.update({ providersInSync });
};

const socket = io(`${getConfig('BACKEND_GRAPHQL_ENDPOINT').replace('/graphql', '')}`, {
  auth: { token: `Bearer ${$authToken.value}` },
  path: '/websocket',
  reconnectionDelay: 10000,
});

const updateSocketTokenAndReconnect = () => {
  socket.auth.token = `Bearer ${$authToken.value}`;
  socket.connect();
};

const disconnectSocket = () => {
  socket.disconnect();
};

const $websocket = Signal({
  client: socket,
  isConnected: false,
  isSyncToastOpen: null,
  syncData: [],
});

socket.on('connect', () => $websocket.update({ isConnected: true }));
socket.on('disconnect', (reason) => {
  $websocket.update({ isConnected: false });
  if (reason === 'io server disconnect') updateSocketTokenAndReconnect();
});
socket.on('ping', () => socket.emit('pong'));

socket.on('organizationSynchronization', onOrganizationSync);

effect(() => {
  if ($user?.value?.currentOrganization?.id !== $user?.previousValue?.currentOrganization?.id ||
    $authToken.value !== $authToken.previousValue
  ) {
    if ($user?.value?.currentOrganization?.id && $authToken.value) updateSocketTokenAndReconnect();
    else disconnectSocket();
  }
}, [$user, $authToken]);
