import { accruClient } from 'api';
import { handleNotification } from 'components/global/Alert/Alert';
import { auth, googleProvider } from 'utils/firebase';
import { validateEmail } from 'utils/validateInput';
import { $global } from 'signals/Global.signals';
import $user from 'signals/User.signals';
import { $authToken } from 'signals/Authentication.signals';
import Signal, { signalManager } from 'signals/Signal';
import $login from 'components/views/Auth/Login/Login.helpers.js';
import logEvent from 'utils/logEvent';
import { AUTH_PROVIDER, ORGANIZATION_USER_ROLE } from '@accru/client';
import history from 'utils/history';
import { $settingsAccounting, fetchAndSetOrganizationAcctProviders } from 'components/views/SettingsAccounting/SettingsAccounting.helpers';

const firebaseErrors = {
  'auth/popup-closed-by-user': 'Your sign in with Google was canceled, please try again.',
  'auth/popup-blocked': 'Please enable popups in your browser to sign in with Google.',
  'auth/cancelled-popup-request': 'Your sign in with Google was canceled, please try again.',
  'auth/invalid-email': 'Please enter a valid email address.',
  'auth/user-not-found': 'Email not found.',
  'auth/wrong-password': 'Incorrect password.',
};

const getFirebaseErrorMessage = (error) => firebaseErrors[error.code] || error.message || 'An error occurred. Please try again.';

export const $acceptInviteDetail = Signal({
  firstName: '',
  lastName: '',
  phoneNumber: '',
  step: 'inviteInfo',
  organization_id: '',
  organization_name: '',
  target_account_exists: null,
  invited_by: '',
  code: '',
  currentPage: 'emailForm',
  organization_invite_id: '',
  email: '',
  isAccepted: false,
});

export const resetState = () => {
  localStorage.clear();
  sessionStorage.clear();
  signalManager.resetAllSignals();
};

export const handleLogout = async ({ expiredFromSession = false }) => {
  try {
    $global.update({
      isLoading: true,
      isLoadingMessage: 'Logging out...',
      isSignedIn: false,
    });

    await auth.signOut();

    $global.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
    });

    resetState();
    if (expiredFromSession) {
      history.push('/login?sessionExpired=true');
    } else {
      history.push('/login');
    }
  } catch (error) {
    $global.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
      isSignedIn: false,
    });
    handleNotification(error);
  }
};

export const fetchAndSetUserData = async () => {
  try {
    const response = await accruClient.users.getCurrent();

    $user.update({
      user: {
        ...$user.value.user,
        ...response,
      },
    });

    if (window.location.pathname !== '/organization-invitation' &&
      window.location.pathname !== '/onboarding') {
      if ($user.value.currentOrganization?.id && $user.value.currentOrganization?.subscriptionLevel) {
        await fetchAndSetOrganizationAcctProviders();
      }
      if ($user.value.currentOrganization?.role !== ORGANIZATION_USER_ROLE.OWNER || ($settingsAccounting.value?.connections && $settingsAccounting.value?.connections?.items?.length > 0)) {
        $user.update({
          isSyncModalOpen: false,
        });
      } else {
        $user.update({
          isSyncModalOpen: true,
        });
      }
    }
    return response;
  } catch (error) {
    if (error.graphQLErrors?.some(e => e.extensions.code === 'UNAUTHENTICATED')) {
      await auth.signOut();
    }
    handleNotification(error);
  }
};

const parseSessionPayloadFromUser = (payload) => ({
  authToken: payload.multiFactor.user.accessToken,
});

export const handleFirebaseLogin = async (fbUser) => {
  const { authToken } = parseSessionPayloadFromUser(fbUser);

  $authToken.update(authToken);

  await fetchAndSetUserData();

  $global.update({
    isSignedIn: true,
  });
};

export const handleFirebaseLogout = () => {
  $global.update({
    isSignedIn: false,
    isLoading: false,
  });

  if (
    window.location.pathname !== '/organization-invitation' &&
    window.location.pathname !== '/onboarding' &&
    window.location.pathname !== '/signup' &&
    window.location.pathname !== '/forgot-password' &&
    window.location.pathname !== '/password-reset'
  ) {
    $user.reset();
    $authToken.reset();

    localStorage.clear();
    sessionStorage.clear();
    history.push('/login');
  }
};

export const handleFirebaseTokenRefresh = (authToken) => $authToken.update(authToken);

export const partialEmailPasswordLoginHandler = async () => {
  try {
    $global.update({
      isLoading: true,
      isLoadingMessage: 'Logging in...',
    });
    const { email, password } = $login.value;
    validateEmail(email);

    if (password.length === 0) {
      throw new Error('Password cannot be blank.');
    }

    await accruClient.users.handleLoginAttempt({ email });

    $login.update({ submitting: true });
    const result = await auth.signInWithEmailAndPassword(email, password);
    logEvent({ name: 'email_login_success' });

    await handleFirebaseLogin(result.user);

    $global.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
    });
    $login.reset();
  } catch (error) {
    $login.update({ submitting: false });
    $login.reset();
    $global.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
    });
    handleNotification(getFirebaseErrorMessage(error), { variant: 'danger' });
  }
};

export const partialGoogleLoginHandler = async () => {
  try {
    const result = await auth.signInWithPopup(googleProvider);
    return await handleFirebaseLogin(result.user);
  } catch (error) {
    handleNotification(getFirebaseErrorMessage(error));
  }
};

export const handleIntuitStartLogin = async () => {
  try {
    $global.update({ isLoading: true, isLoadingMessage: 'Logging in...' });
    const response = await accruClient.auth.startIntuitLogin({
      authProvider: AUTH_PROVIDER.INTUIT,
    });
    logEvent({ name: 'qb_login_start' });
    window.location.href = response;
    return response;
  } catch (error) {
    $global.update({ isLoading: false, isLoadingMessage: 'Loading...' });
    handleNotification(error);
  }
};

export const performIntuitCallbackHandoff = async (url) => {
  try {
    $global.update({
      isLoading: true,
      isLoadingMessage: 'Logging in...',
    });
    const response = await accruClient.auth.finishIntuitLogin({ authorizationToken: url, authProvider: AUTH_PROVIDER.INTUIT });
    const authResult = await auth.signInWithCustomToken(response);
    $login.update({ submitting: true });
    await fetchAndSetUserData();
    logEvent({ name: 'qb_login_success' });
    $global.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
    });
    return handleFirebaseLogin(authResult.user);
  } catch (error) {
    $login.update({ submitting: false });
    $login.reset();
    $global.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
    });
    handleNotification(getFirebaseErrorMessage(error), { variant: 'danger' });
  } finally {
    $login.update({ submitting: false });
    $login.reset();
  }
};
