import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import { handleLogout } from 'components/views/Auth/_shared/Auth.helpers';
import { $areYouStillHereModalOpen, $areYouStillHereTimerId, $authTimerId } from 'signals/Global.signals';
import { auth } from './firebase';

export const signIn = async (email, password) => {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  await auth.signInWithEmailAndPassword(email, password);
};

export const signInWithGoogle = async () => {
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  await auth.signInWithPopup(googleProvider);
};

export const signOut = async () => {
  await auth.signOut();
};

export const createNewUser = async (email, password) => {
  const user = await auth.createUserWithEmailAndPassword(email, password);
  return user;
};

export const sendPasswordResetEmail = async (email) => {
  await auth.sendPasswordResetEmail(email);
};

export const getFirebaseToken = async () => {
  if (firebase.auth().currentUser) {
    return firebase.auth().currentUser.getIdToken();
  }
  return null;
};

export const currentUser = async () => {
  await firebase.auth().currentUser.currentUser();
};

export const fetchUserInfo = async (userId) => {
  try {
    const userRef = firebase.firestore().collection('users').doc(userId);

    return userRef.get().then((doc) => {
      if (doc.exists) {
        return doc.data();
      }
      return {};
    }).catch((error) => error);
  } catch (error) {
    return error;
  }
};

export const startSessionTimer = () => {
  $authTimerId.value = setTimeout(() => {
    console.info('Session expired. Logging out...');
    handleLogout({ expiredFromSession: true });
  }, 30 * 60 * 1000); // 30 minutes in milliseconds)

  $areYouStillHereTimerId.value = setTimeout(() => {
    $areYouStillHereModalOpen.value = true;
  }, 25 * 60 * 1000); // 25 minutes in milliseconds)
};

export const resetSessionTimer = () => {
  if ($authTimerId.value && $areYouStillHereTimerId.value) {
    clearTimeout($authTimerId.value);
    clearTimeout($areYouStillHereTimerId.value);
  }
  startSessionTimer();
};
