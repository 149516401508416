import Loadable from 'components/global/Loadable';
import { Form } from 'react-bootstrap';

const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    const cleanedNumber = phoneNumber.replace(/\D/g, '');
    if (cleanedNumber.length >= 11 && cleanedNumber.startsWith('1')) {
      return `+1 (${cleanedNumber.slice(1, 4)}) ${cleanedNumber.slice(4, 7)}-${cleanedNumber.slice(7)}`;
    } if (cleanedNumber.length >= 10) {
      return `(${cleanedNumber.slice(0, 3)}) ${cleanedNumber.slice(3, 6)}-${cleanedNumber.slice(6)}`;
    }
    return cleanedNumber;
  }
  return '';
};

const verifyChange = (e) => {
  if ((!e.target.value.startsWith('1') || !e.target.value.startsWith('+1') || !e.target.value.startsWith('(')) && e.target.value.length < 14) {
    return true;
  } if ((e.target.value.startsWith('1') || e.target.value.startsWith('+1') || e.target.value.startsWith('(')) && e.target.value.length < 17) {
    return true;
  }
  return false;
};

const PhoneNumberInput = ({
  name,
  className,
  placeholder,
  value,
  signal,
  loadableAnimation,
  variant = 'form-control', // || form-control-border
  customOnChange, // ONLY USE IF NEEDED
}) => {
  if (!signal || !name) {
    return new Error(`PhoneNumberInput has no signal or name (Name: ${name})`);
  }
  return (
    <Loadable signal={signal} animation={loadableAnimation} className={className}>
      <Form.Control
        className={`${variant} bg-white ${className}`}
        autoComplete="off"
        id={name}
        type="text"
        placeholder={placeholder || 'Phone Number'}
        name={name}
        value={formatPhoneNumber(value) || formatPhoneNumber(signal?.value?.[name])}
        onChange={(e) => {
          if (verifyChange(e)) {
            if (customOnChange) {
              customOnChange(e);
            } else {
              signal.update({
                [name]: formatPhoneNumber(e.target.value),
              });
            }
          }
        }}
      />
    </Loadable>
  );
};

export default PhoneNumberInput;
